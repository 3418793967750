/*** IMPORTS FROM imports-loader ***/


(function() {
  angular.module('adhesiveApp').service('FeaturesService', ['$http', 'userPrefixUrl', 'WaterJwt', 'UserService',
    function($http, userPrefixUrl, WaterJwt, UserService) {
      const getFeaturesUrl = userPrefixUrl + '/user/features';

      this.getFeatures = async () => {
        const resp = await $http.get(getFeaturesUrl);
        return resp.data.features;
      };
    }
  ])
})();

