/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp').service('analytics7daysService', ['$filter', 'utils', 'time', function($filter, utils, time) {
    var addDataToChartData = function(data) {
        if(data === null) {
            return [[]];
        }
        var chartData = [], i;
        for(i = 0; i < data.length; i++) {
            if(data[i].value !== null) {
                chartData.push([-data[i].days_ago, data[i].value]);
            }
        }
        return chartData;
    };

	this.setChartData = function(data) {
		var chartData = {}, i, date = time.getNowDate();
        chartData.data = [addDataToChartData(data)];
        chartData.options = {
            yaxis : {
                show: false,
                min: 0,
                max: 1
            },
            xaxis : {
                show:  false,
                min: -6,
                max: 0
            },
            grid : {
                show : true,
                color: '#dddddd',
                borderWidth: {top: 0, right: 0, left: 0, bottom: 1},
                margin: 0
            },
            color: '#ffc200',
            shadowSize: 0
        };
        chartData.dates = [];
        for(i = 0; i < 3; i++) {
            var dateOld = new Date(date);
	        dateOld.setDate(date.getDate() - i*3);
	        chartData.dates.push($filter('date')(dateOld, 'MMM d'));
        }
		return chartData;
	};
	this.setRankingPositionMsg = function(rankingValue) {
        return utils.selectValueFromArrayByNumber(['top', 'second', 'bottom'], rankingValue);
    };
    this.setNoLabelsChartData = function(data) {
        var chartData = {};
        chartData.data = [addDataToChartData(data)];
        chartData.options = {
            yaxis : {
                show: false
            },
            xaxis : {
                show:  false
            },
            grid : {
                show : false,
                margin: 0
            },
            color: '#ffc200',
            shadowSize: 0
        };
        return chartData;
    };
}]);
