/*** IMPORTS FROM imports-loader ***/


(function() {
  'use strict';
  angular.module('adhesiveApp').service('EventsList', ['$http', 'newApiPrefixUrl',
    function($http, newApiPrefixUrl) {
      var url = newApiPrefixUrl + '/events/conversions';
      var that = this;

      this.getConversionList = function(params) {
        // Needed params: user_id, from_date, to_date, page, order_key, order_type, hide_visitors
        return new Promise(function(resolve, reject){
          var options = {
            params: params
          };
          $http.get(url, options).then(resolve, reject);
        });
      }
    }
  ])
})();

