/*** IMPORTS FROM imports-loader ***/


(function() {
  'use strict';
  angular.module('adhesiveApp').service('WaterJwt', ['$http', 'apiPrefixUrl', 'userPrefixUrl',
    function($http, apiPrefixUrl, userPrefixUrl) {
      const
        url = apiPrefixUrl + '/jwt',
        getUserByAirIdUrl = userPrefixUrl + '/user/air',
        that = this,
        cache = {
          "user_air_id": null,
          "overwrittenUser": null,
        };

      this.setUserAirId = (air_id) => {
        cache.user_air_id = air_id;
      }

      const getOverwrittenUser = async (jwt) => {
        if(cache.user_air_id === null) {
          return null;
        }
        if(cache.overwrittenUser) {
          return cache.overwrittenUser;
        }
        const options = {cache: false};
        const result = await $http.get(getUserByAirIdUrl + "?user_air_id=" + cache.user_air_id, options);
        cache.overwrittenUser = result.data.user;
        return cache.overwrittenUser;
      };

      this.getFromCache = function() {
        if (localStorage.waterjwt) {
          return angular.fromJson(localStorage.waterjwt);
         } else {
           return null;
         }
      };

      this.setCache = function(data) {
        localStorage.waterjwt = angular.toJson(data);
      };

      this.getFromBackend = function () {
        return $http.get(url);
      };

      this.get = function () {
        return new Promise(function(resolve, reject){
          if (!that.getFromCache()) {
            that.getFromBackend().then(function (resp) {
              that.setCache(resp.data);
              resolve(that.getFromCache());
            });
          } else {
            resolve(that.getFromCache());
          }
         });
      }

      localStorage.waterjwt = null;
    }
  ])
})();

