/*** IMPORTS FROM imports-loader ***/


(function(angular){
  angular.module('adhesiveApp').service('piggybackUrls', piggybackUrls);

  function piggybackUrls () {
    var jsUrls = {
      secureU: function (uuid) { return '<script src="https://js.b1js.com/tagcontainer.js?id=' + uuid + '&type=1"></script>'},
      nonSecureU: function (uuid) { return '<script src="http://js.b1js.com/tagcontainer.js?id=' + uuid + '&type=1"></script>'},
      secureC: function (uuid) { return '<script src="https://js.b1js.com/tagcontainer.js?id=' + uuid + '&type=0&redir=[REDIRECT_URL]&order_id=[ORDER_ID]&value=[REVENUE]"></script>'},
      nonSecureC: function (uuid) { return '<script src="http://js.b1js.com/tagcontainer.js?id=' + uuid + '&type=0&redir=[REDIRECT_URL]&order_id=[ORDER_ID]&value=[REVENUE]"></script>'}
    };

    var imageUrls = {
      secureU: function (uuid) { return '<img src="https://b1img.com/' + uuid + '/uni_tag">'},
      nonSecureU: function (uuid) { return '<img src="http://b1img.com/' + uuid + '/uni_tag">'},
      secureC: function (uuid) { return '<img src="https://b1img.com/' + uuid + '/con_tag?order_id=[ORDER_ID]&value=[REVENUE]&redir=[REDIRECT_URL]">'},
      nonSecureC: function (uuid) { return '<img src="http://b1img.com/' + uuid + '/con_tag?order_id=[ORDER_ID]&value=[REVENUE]&redir=[REDIRECT_URL]">'}
    };

    var ampUrls = {
      conversion: function (uuid) {
        return (
          '<amp-geo layout="nodisplay">\n' +
          '  <script type="application/json">{"ISOCountryGroups": {"eu": ["at","be","bg","hr","cy","cz","dk","ee","fi","fr","gr","es","nl","ie","lt","lu","lv","mt","de","pl","pt","ro","sk","si","se","hu","gb","it","no","ch"]}}</script>\n' +
          '</amp-geo>\n' +
          '<amp-analytics config="//js.b1js.com/tags/amp_conv_AMP_GEO_' + uuid + '.json">\n' +
          '  <script type="application/json">{"vars": {"order_id": "[ORDER_ID]","value": "[REVENUE]", "redir": "[REDIRECT_URL]"}}</script>\n' +
          '</amp-analytics>'
        );
      },
      universal: function (uuid) {
        return (
          '<amp-geo layout="nodisplay">\n' +
          '  <script type="application/json">{"ISOCountryGroups": {"eu": ["at","be","bg","hr","cy","cz","dk","ee","fi","fr","gr","es","nl","ie","lt","lu","lv","mt","de","pl","pt","ro","sk","si","se","hu","gb","it","no","ch"]}}</script>\n' +
          '</amp-geo>\n' +
          '<amp-analytics config="//js.b1js.com/tags/amp_uni_AMP_GEO_' + uuid + '.json"></amp-analytics>'
        );
      }
    };

    var headAmp = (
      '<script async custom-element="amp-analytics" src="https://cdn.ampproject.org/v0/amp-analytics-0.1.js"></script>\n' +
      '<script async custom-element="amp-geo" src="https://cdn.ampproject.org/v0/amp-geo-0.1.js"></script>'
    );

    function makeUrl(url_function, websiteId) {
      return url_function(websiteId);
    }

    this.getJsUniversalUrl = function (websiteId, secure) {
      var url;
      if (secure) {
        url = jsUrls.secureU;
      } else {
        url = jsUrls.nonSecureU;
      }
      return makeUrl(url, websiteId);
    };

    this.getJsConversionUrl = function (websiteId, secure) {
      var url;
      if (secure) {
        url = jsUrls.secureC;
      } else {
        url = jsUrls.nonSecureC;
      }
      return makeUrl(url, websiteId);
    };

    this.getImageUniversalUrl = function (websiteId, secure) {
      var url;
      if (secure) {
        url = imageUrls.secureU;
      } else {
        url = imageUrls.nonSecureU;
      }
      return makeUrl(url, websiteId);
    };

    this.getImageConversionUrl = function (websiteId, secure) {
      var url;
      if (secure) {
        url = imageUrls.secureC;
      } else {
        url = imageUrls.nonSecureC;
      }
      return makeUrl(url, websiteId);
    };

    this.getAmpConversionUrl = function (websiteId) {
      return makeUrl(ampUrls.conversion, websiteId);
    };

    this.getAmpUniversalUrl = function (websiteId) {
      return makeUrl(ampUrls.universal, websiteId);
    };

    this.headAmp = headAmp;

  }

})(angular);

