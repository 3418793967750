/*** IMPORTS FROM imports-loader ***/


angular.module('adhesiveApp')
    .service('TagService', ['$http', 'apiPrefixUrl',
        function($http, apiPrefixUrl) {

          this.getSites = function(userID) {
            return $http.get(apiPrefixUrl + '/tagmanager/tags/' + userID);
          };

          this.addSite = function(obj, advertiserId) {
            var siteName = obj.siteName;
            var segmentId = obj.segmentId;
            return $http.post(apiPrefixUrl + '/tagmanager/site/manage', {
              advertiser_profile: advertiserId,
              name: siteName,
              segment: segmentId
            });
          };

          this.editSite = function(name, id){
            return $http.put(apiPrefixUrl + '/tagmanager/site/manage/' + id, {name: name});
          };

          this.updateSegmentSite = function (segmentId, siteId) {
            return $http.put(apiPrefixUrl + '/tagmanager/site/manage/' + siteId, {segment: segmentId});
          };

          this.deleteSite = function(id){
            return $http.delete(apiPrefixUrl + '/tagmanager/site/manage/'+id);
          };

          this.getTags = function(userId){
            if(userId) {
              return $http.get(apiPrefixUrl + '/tagmanager/ar/sites?user_id=' + userId);
            }
            else {
              return $http.get(apiPrefixUrl + '/tagmanager/ar/sites');
            }
          };

          this.changeTagsForSite = function(type, json, advertiserId){
            switch(type){
              case 'amp_universal':
                return $http.post(apiPrefixUrl + '/tagmanager/amp_universal_tag/' + advertiserId + '/save-changes', json);
                break;
              case 'amp_conversion':
                return $http.post(apiPrefixUrl + '/tagmanager/amp_conversion_tag/' + advertiserId + '/save-changes', json);
                break;
              case 'js_universal':
                return $http.post(apiPrefixUrl + '/tagmanager/universal_tag/' + advertiserId + '/save-changes', json);
              case 'img_universal':
                return $http.post(apiPrefixUrl + '/tagmanager/img_universal_tag/' + advertiserId + '/save-changes', json);
              case 'js_conversion':
                return $http.post(apiPrefixUrl + '/tagmanager/conversion_tag/' + advertiserId + '/save-changes', json);
              case 'img_conversion':
                return $http.post(apiPrefixUrl + '/tagmanager/img_conversion_tag/' + advertiserId + '/save-changes', json);
            }
          };
        }
      ]);

