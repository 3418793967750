/*** IMPORTS FROM imports-loader ***/


(function() {
  'use strict';
  angular.module('adhesiveApp').service('CreativeList', ['$http', 'newApiPrefixUrl', 'utils',
    function($http, newApiPrefixUrl, utils) {
      var url = newApiPrefixUrl + '/creative_reports',
        that = this;

      this.excel_url = url + "/excel";

      this.getCreativeList = function(params) {
        // Needed params: user_id, from_date, to_date, page, order_key, order_type
        return new Promise(function(resolve, reject){
          var options = {
            params: params
          };
          $http.get(url, options).then(resolve, reject);
        });
      };

    }
  ])
})();

