/*** IMPORTS FROM imports-loader ***/


(function() {
  angular.module('adhesiveApp').service('TableTwo', ["$location",
    function($location) {
      let setParams = function(newState) {
        let oldState = $location.search();
        $location.search(Object.assign(oldState, newState))
      };

      let addColumnProvider = (options, state) => (label, index, getValueSpecialized, getClassesSpecialized,) => {
        return {
          label: label,
          index: index,
          sortable: index != undefined,
          isSortable: function() {
            return state.completed && this.sortable
          },
          isSorted: function(direction) {
            var isSorted = this.isSortable() && options.order_by.index == this.index;
            if (direction === undefined) {
              return isSorted;
            } else {
              return isSorted && direction == options.order_by.reverse;
            }
          },
          setOrder: function() {
            if (this.sortable) {
              options.order_by.index = this.index;
              if (options.order_by.index == this.index) {
                options.order_by.reverse = !options.order_by.reverse;
              } else {
                options.order_by.index = this.index;
                options.order_by.reverse = true;
              }
              setParams({sort_by: this.index, direction: options.order_by.reverse ? "desc":"asc" });
            }
          },
          getValue: function(request) {
            if(getValueSpecialized) {
              return getValueSpecialized(request[index], request);
            } else {
              return request[index];
            }
          },
          getClasses: function(request) {
            if(getClassesSpecialized) {
              return getClassesSpecialized(request[index], request);
            } else {
              return "";
            }
          }
        }
      };

      return {addColumnProvider, setParams};
    }
]);
})();

