/*** IMPORTS FROM imports-loader ***/


'use strict';


angular.module('adhesiveApp').factory('ReportTable', [function () {
  return function(tableParams){
    var appendOrderingParam = function(params, query) {
       // get first sort and set order
      var orderBy = null;
      var sorting = params.sorting() || {};
      for(var sortKey in sorting) {
        var sortValue = sorting[sortKey];
        orderBy = {field: sortKey, direction: sortValue};
        break;
      }
      if (orderBy) {
        query.ordering = (orderBy.direction == 'asc' ? '' : '-') + orderBy.field;
      }
      return query;
    }

    var setOrder = function (sortable) {
      if (!sortable) {
        return;
      }
      var sorting = tableParams.sorting();
      var sortingValue = sorting && sorting[sortable];
      var directionIsAsc = sortingValue === 'asc';
      var sortingParams = {};
      sortingParams[sortable] = directionIsAsc ? 'desc' : 'asc';
      tableParams.parameters({ sorting: sortingParams });
    };

    var onChanges = function (changesObj) {
      var filter = changesObj.filterUsername;
      if (filter && !filter.isFirstChange()) {
        tableParams.reload();
      }
    };

    return {
      'setOrder': setOrder,
      'tableParams': tableParams,
      'onChanges': onChanges,
      'appendOrderingParam': appendOrderingParam
    };
  }
}]);

