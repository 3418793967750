/*** IMPORTS FROM imports-loader ***/


(function() { 
    'use strict';
    angular.module('adhesiveApp')
        .service('FormMessages', ['ERROR_MESSAGES',
            function(ERROR_MESSAGES) {
              this.prepErrArr = function(formObj) {
                var errorObj = {};
                angular.forEach(formObj.$error, function(v,k) {
                  angular.forEach(v, function(v1,k1){
                    errorObj[v1.$name] = errorObj[v1.$name] || [];
                    errorObj[v1.$name].push(ERROR_MESSAGES[v1.$name][k]);
                  });
                });
                if(formObj.$invalid){
                  errorObj['all'] = [ERROR_MESSAGES['all']];
                }
                return errorObj;
              }
                }]);
})();

