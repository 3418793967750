/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp').factory('Card', ['$resource', 'apiPrefixUrl',
  function($resource, apiPrefixUrl){
    return $resource(apiPrefixUrl + '/payment/cards/:payment_id',
                     {'payment_id': '@payment_id'},
                     {update: {method: 'PUT', isArray: false}});
}]);

