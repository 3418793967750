/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .service('utils', ['$templateCache', '$http',
    function($templateCache, $http) {

        this.getTemplate = function(path, callback){
            var cachedTempl = $templateCache.get(path);

            if (cachedTempl){
                callback(cachedTempl);
            }
            else {
                $http.get(path).then(function(resp){
                    var data = resp.data;
                    $templateCache.put(path, data);
                    callback(data);
                });
            }
        }

        this.getUrlWithSearchParams = function(url, params){
            var searchElements = [];

            angular.forEach(params, function(v, k){
                k = encodeURIComponent(k);
                v = encodeURIComponent(v);
                searchElements.push(k + '=' + v);
            });

            return url + '?' + searchElements.join('&');
        }

        this.precision = function(number){
            var numsplit = String(number).split('.');
            if(numsplit.length == 2){
                return numsplit[1].length;
            }
            else {
                return 0;
            }
        }

        this.haveValue = function(value){
            if(angular.isNumber(value)){
                return true;
            }

            if(typeof value === 'boolean'){
                return true;
            }

            return !!value;
        }

        this.isEmpty = function(value){
            return angular.isUndefined(value) || value === '' || value === null || value !== value;
        }

        this.selectValueFromArrayByNumber = function(array, number) {
            var arrayLength = array.length;
            return array[Math.min(arrayLength - 1, Math.floor(number * arrayLength))];
        };
    }]);

