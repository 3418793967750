/*** IMPORTS FROM imports-loader ***/



(function(){
    'use strict';

    var userServHelper = function(userServ, $resource, time, apiPrefixUrl){
        this.userServ = userServ;
        this.balance = $resource(apiPrefixUrl + '/billing/balance');
        this.time = time;
    }

    userServHelper.$inject = ['userServ', '$resource', 'time', 'apiPrefixUrl'];

    userServHelper.prototype.getBalance = function(callback, params){
        var now = this.time.getNowDate();
        params = params || {};
        if(!this.userServ.isRegistered()){
            this.$rootScope.$broadcast('loginRequired');
            return null;
        }

        this.balance.get(params, function(res){
            if(res.balance){
                callback(res.balance);
            }
        }, function(){
            alert('Server communication error');
        });
    }

    angular.module('adhesiveApp').service('userServHelper', userServHelper);
})();


