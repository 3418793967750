/*** IMPORTS FROM imports-loader ***/


(function() {
  'use strict';
  angular.module('adhesiveApp')
    .service('customSegments', ['$http', 'time', 'apiPrefixUrl',
      function($http, time, apiPrefixUrl) {

        function convertCreatedAt (obj) {
          var dt =  new Date(obj.created_at);
          obj.created_at = time.utc(dt);
        }

        function convertCreatedAtInListResponse (resp) {
          resp.data.forEach(convertCreatedAt);
          return resp;
        }

        function convertCreatedAtInResponse (resp) {
          convertCreatedAt(resp.data);
          return resp;
        }

        this.getCustomSegments = function() {
          return $http
            .get(apiPrefixUrl + '/appnexus/custom_segments')
            .then(convertCreatedAtInListResponse);
        };

        this.addCustomSegment = function(segmentData){
        	return $http
            .post(apiPrefixUrl + '/appnexus/custom_segments', segmentData)
            .then(convertCreatedAtInResponse);
        };

        this.deleteCustomSegment = function(id){
        	return $http.delete(apiPrefixUrl + '/appnexus/custom_segments/' + id);
        };

        this.updateCustomSegment = function(id, segmentData){
        	return $http
            .put(apiPrefixUrl + '/appnexus/custom_segments/' + id, segmentData)
            .then(convertCreatedAtInResponse);
        };
      }
    ]);
})();

