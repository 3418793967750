/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp').service('awarenessRatingService', ['utils', function(utils) {
	this.setPieChartData = function(awarenessRatingData) {
        var awarenessRatingPie = {};
        awarenessRatingData *= 100;
        awarenessRatingPie.data = [{data: 100 - awarenessRatingData, color: '#444444'},
            {data: awarenessRatingData, color: '#ffc200'}];
        awarenessRatingPie.options = {
            series: {
                pie: {
                    show: true,
                    stroke: {
                        width: 0
                    }
                }
            }
        };
        return awarenessRatingPie;
    };
    this.setRankingPositionMsg = function(awarenessRatingData) {
        return utils.selectValueFromArrayByNumber(['top', 'second', 'bottom'], awarenessRatingData);
    };
    this.setTrendChartData = function(awarenessRatingData) {
        var addPointInTrendChart, awarenessRatingTrend = {}, halfLineWidth = 0.004,
            firstElementWithPosition, lastElementWithPosition, i;
        awarenessRatingTrend.options = {
            yaxis : {
                show: false,
                min: 0,
                max: 1
            },
            xaxis : {
                show:  true,
                max: 0,
                min: -89,
                font: {
                    size: 0,
                    lineHeight: 0
                },
                color: '#3b3b3b',
                tickSize: 1
            },
            grid : {
                show : true,
                color: '#3b3b3b',
                borderWidth: {top: 1, right: 1, left: 1, bottom: 0},
                margin: 2
            }
        };
        awarenessRatingTrend.data = [{data: []},{data: []}];
        if(awarenessRatingData === null) {
            return awarenessRatingTrend;
        }
        for(i = 0; i<awarenessRatingData.length; i++) {
            if(awarenessRatingData[i].position !== null) {
                firstElementWithPosition = awarenessRatingData[i];
                break;
            }
        }
        for(i = awarenessRatingData.length - 1; i >= 0; i--) {
            if(awarenessRatingData[i].position !== null) {
                lastElementWithPosition = awarenessRatingData[i];
                break;
            }
        }
        for(i = 0; i < awarenessRatingData.length; i++) {
            if(awarenessRatingData[i].value !== null) {
                awarenessRatingTrend.data[0].data.push([-awarenessRatingData[i].days_ago, awarenessRatingData[i].value]);
            }
            if(awarenessRatingData[i].position !== null) {
                awarenessRatingTrend.data[1].data.push([-awarenessRatingData[i].days_ago, 1-awarenessRatingData[i].position - halfLineWidth,
                    1-awarenessRatingData[i].position + halfLineWidth]);
            }
        }
        awarenessRatingTrend.data[0].lines = {
            show: true,
            fill: true,
            fillColor: '#1a1a1a'
        };
        awarenessRatingTrend.data[0].color = '#1a1a1a';
        awarenessRatingTrend.data[1].color =  {opacity: 0};

        awarenessRatingTrend.data[1].lines = {
            show: true,
            fill: true,
            fillColor: {colors: ['#FF0000', '#ffc600', '#44ba27']}
        };

        addPointInTrendChart = function(x, y) {
            var color = utils.selectValueFromArrayByNumber(['#44ba27', '#ffc600', '#FF0000'], y);
            awarenessRatingTrend.data.push({
                data: [[x, 1-y]],
                points: {
                    show: true,
                    lineWidth: 0,
                    radius: 5,
                    fillColor: color
                }
            });
        };
        
        addPointInTrendChart(-lastElementWithPosition.days_ago, lastElementWithPosition.position);
        addPointInTrendChart(-firstElementWithPosition.days_ago, firstElementWithPosition.position);

        return awarenessRatingTrend;
    };
}]);
