/*** IMPORTS FROM imports-loader ***/


(function() {
  angular.module('adhesiveApp').service('UserService', ['$http', 'userPrefixUrl', 'WaterJwt', 'userServ',
    function($http, userPrefixUrl, WaterJwt, userServ) {
      let that = this,
        getNotificationsUrl = userPrefixUrl + '/notifications/list',
        acknolageUrl = userPrefixUrl + '/notifications/mark_read',
        cache = {};

      let sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

      this.getNotifications = () => {
        const startDate = Date.now();
        return new Promise(function(resolve, reject){
          let counter = 0;
          let waitForData = () => {
            const currentDate = Date.now();;
            if(currentDate - startDate > 10000) {
              console.error("Couldn't get the notifications data.");
              reject("Couldn't get the notifications data.");
              return;
            };
            if(!cache["notifications"]) {
              sleep(100).then(waitForData);
            } else {
              resolve(cache);
            }
          };
          waitForData();
        });
      };

      this.markNotificationRead = (notification_id) => {
        return new Promise(function(resolve, reject){
          let options = {cache: true},
            params = {notification_id};

          $http.post(acknolageUrl, params, options).then(function(resp) {
            resolve(resp.data.notifications);
          });
        });
      };

      this.forceRefreshNotifications = (page) => new Promise((resolve, reject) => {
        let options = {
          cache: false,
          params: {
            page: page.currentPage - 1,
            pagesize: page.pagesize,
          }};

        $http.get(getNotificationsUrl, options).then((resp) => {
          cache["notifications"] = resp.data.notifications;
          cache["pageinfo"] = resp.data.pageinfo;
          resolve(cache);
        }, () => reject("Notifications could not be loaded"));
      });

      let refreshNotificationsPeriodTask = () => {
        if(!userServ.isAdmin()) {
          return;
        }
        let options = {cache: false};

        $http.get(getNotificationsUrl, options).then((resp) => {
          cache["notifications"] = resp.data.notifications;
          setTimeout(refreshNotificationsPeriodTask, 5000);
        }, () => {
          setTimeout(refreshNotificationsPeriodTask, 5000)}
        );
      };
      refreshNotificationsPeriodTask();
    }
  ])
})();

