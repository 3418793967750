/*** IMPORTS FROM imports-loader ***/


(function() {
  angular.module('adhesiveApp').service('ExcelService', ['$http', 'excelPrefixUrl',
    function($http, excelPrefixUrl) {
      var that = this,
        monthlyReportsByAdvUrl = excelPrefixUrl + '/monthly_report_by_advertiser',
        salesRepListUrl = excelPrefixUrl + '/sales_rep_list',
        revenueReport = excelPrefixUrl + '/revenue_report';

      var downloadExcel = function(url, params) {
        return new Promise(function(resolve, reject){
          var options = {
            params: params,
            cache: false,
            responseType: 'arraybuffer'};

          $http.get(url, options).then(function(resp) {
            var file = new Blob([resp.data], {
                type: 'application/vnd.ms-excel'
            });
            //trick to download store a file having its URL
            var fileURL = URL.createObjectURL(file);
            var a = document.createElement('a');
            a.href = fileURL;
            a.target = '_blank';
            a.download = 'report.xls';
            document.body.appendChild(a); //create the link "a"
            a.click(); //click the link "a"
            document.body.removeChild(a); //remove the link "a"
            resolve();
          }, reject);

        });
      };

      this.downloadMonthlyReportsByAdv = function(advertiserIds, month) {
        var params = {
          'aids': advertiserIds.join(",")
        };
        if(month) {
          params['month'] = month;
        }
        return downloadExcel(monthlyReportsByAdvUrl, params);
      };

      this.downloadSalesRepList = function(account_manager, salesman, left_from_date, left_to_date, right_from_date, right_to_date) {
        var params = {
          'left_from_date': left_from_date,
          'left_to_date': left_to_date,
          'right_from_date': right_from_date,
          'right_to_date': right_to_date,
        };
        if(account_manager) {
          params['account_manager'] = account_manager;
        }
        if(salesman) {
          params['salesman'] = salesman;
        }
        return downloadExcel(salesRepListUrl, params);
      };

      this.downloadRevenueReport = function(from_date, to_date) {
        return new Promise(function(resolve, reject){
          var params = {
            'from_date': from_date,
            'to_date': to_date,
          };
          return downloadExcel(revenueReport, params);
        });
      }


    }
  ])
})();

