/*** IMPORTS FROM imports-loader ***/


(function() {
  'use strict';
  angular.module('adhesiveApp')
    .service('SettingsService', ['$http', 'apiPrefixUrl',
      function($http, apiPrefixUrl) {
        this.getTimeZoneList = function() {
          return $http.get(apiPrefixUrl + '/timezones');
        };
        this.getUserSettings = function(id) {
          return $http.get(apiPrefixUrl + '/accounts/settings/' + id);
        };
      }
    ]);
})();

