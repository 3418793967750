/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp').factory(
    'UserModel', ['$resource', 'apiPrefixUrl',
         function($resource, apiPrefixUrl) {
    var UserModel = $resource(apiPrefixUrl + '/appnexus/advertisers/:id/:action',
        {id: '@id'},
        {
            update: {method: 'PATCH', isArray: false},
            debit: {method: 'POST', params: {action: 'debit'}},
            credit: {method: 'POST', params: {action: 'credit'}}
        });

    return UserModel;
}]);

