/*** IMPORTS FROM imports-loader ***/


(function() {
  'use strict';
  angular.module('adhesiveApp')
    .service('FavoriteUsersList', ['$http', 'apiPrefixUrl', '$window',
      function($http, apiPrefixUrl, $window) {
        var url = apiPrefixUrl + '/appnexus/favorite-users';
        var that = this;

        this.getFromCache = function() {
          return new Promise(function(resolve, reject){
            var favoriteUsers = that._get();
            if (!favoriteUsers) {
              $http.get(url).then(
                function(resp){
                  that._set(resp.data);
                  resolve(resp.data);
                }, reject);
            } else {
              resolve(favoriteUsers);
            }
          })
        };

        this._get = function() {
          return angular.fromJson($window.localStorage.favoriteUsers);
        };

        this._set = function(data) {
          $window.localStorage.favoriteUsers = angular.toJson(data);
        };

        this.setActive = function(active) {
          var favoriteUsers = that._get();
          favoriteUsers = favoriteUsers || {};
          favoriteUsers.active = active;
          that._set(favoriteUsers);
        }

        this.setSelectedUsers = function(resp) {
          that._set(resp.data);
        }
      }
    ]);
})();

