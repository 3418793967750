/*** IMPORTS FROM imports-loader ***/


(function() {
  'use strict';
  angular.module('adhesiveApp')
    .service('SegmentList', ['$http', 'apiPrefixUrl', '$window',
      function($http, apiPrefixUrl, $window) {
        this.getSegmentList = function() {
          return $http.get(apiPrefixUrl + '/analytics/segments');
        };

        this.getCachedSegmentList = function() {
          return new Promise(function(resolve, reject){
            var segments = angular.fromJson($window.localStorage.segmentList)
            if (!segments) {
              $http.get(apiPrefixUrl + '/analytics/segments').then(function(resp) {
                $window.localStorage.segmentList = angular.toJson(resp.data);
                resolve(resp.data);
              })
            } else {
              resolve(segments);
            }
          })
        };
      }
    ]);
})();

