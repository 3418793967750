/*** IMPORTS FROM imports-loader ***/


(function() {
  angular.module('adhesiveApp').service('AdvertiserService', ['$http', 'advertiserPrefixUrl',
    function($http, advertiserPrefixUrl) {
      var that = this,
        getAdvertisersUrl = advertiserPrefixUrl + '/advertisers/',
        getSalesRepOptionsUrl = advertiserPrefixUrl + '/salesrep';

      this.getAdvertiser = function(advertiserId) {
        return new Promise(function(resolve, reject){
          that.fetchAdvertisers().then(function(advertisers){
            resolve(advertisers[advertiserId]);
          });
        });
      };

      this.fetchAdvertisers = function() {
        return new Promise(function(resolve, reject){
            var advertisers = {},
              options = {cache: true};

            $http.get(getAdvertisersUrl, options).then(function(resp) {
              for(var index=0; index < resp.data.advertisers.length; index++) {
                var row = resp.data.advertisers[index];
                var id = row.id.replaceAll('-','');
                advertisers[ id ] = {
                  id: id,
                  name: row.name,
                  state: row.state,
                  timezone: row.timezone,
                  salesperson: row.salesperson,
                  business_relationship: row.business_relationship
                };
              }
              resolve(advertisers);
            });
        });
      };

      this.fetchSalesRepOptions = function() {
        return new Promise(function(resolve, reject){
            var advertisers = {},
              options = {cache: true};

            $http.get(getSalesRepOptionsUrl, options).then(function(resp) {
              resolve(resp.data.sales_rep_options);
            });
        });
      };
    }
  ])
})();

