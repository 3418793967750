/*** IMPORTS FROM imports-loader ***/


(function() {
  'use strict';
  angular.module('adhesiveApp').service('XandrService', ['$http', 'xandrPrefixUrl',
    function($http, xandrPrefixUrl) {
      var that = this,
        reportsInfoUrl = xandrPrefixUrl + '/reports/info',
        raportsInfoActiveUrl = xandrPrefixUrl + '/reports/info/active',
        startReportsUrl = xandrPrefixUrl + '/reports/start';

        this.getActiveRequests = function() {
          return new Promise(function(resolve, reject){
            var options = {cache: false};

            $http.get(raportsInfoActiveUrl, options).then(function(resp) {
              resolve(resp.data.requests);
            });
        });
      };

      this.getRequests = function(request_ids) {
          return new Promise(function(resolve, reject){
            var options = {cache: false},
              params = {
                request_ids: request_ids
              };

            $http.post(raportsInfoActiveUrl, params, options).then(function(resp) {
              resolve(resp.data.requests);
            });
        });
      };

      this.getRequestsFromRange = function(startDate, endDate) {
          return new Promise(function(resolve, reject){
            var options = {cache: false},
              url = reportsInfoUrl +'?from_date='+ startDate.toISOString().slice(0, 10) +'&to_date='+ endDate.toISOString().slice(0, 10);

            $http.get(url, options).then(function(resp) {
              resolve(resp.data.requests);
            });
        });
      };

      this.startReports = function(startDate, endDate) {
          return new Promise(function(resolve, reject){
            var options = {cache: false},
              params = {
                start_date: startDate,
                end_date: endDate
              };

            $http.post(startReportsUrl, params, options).then(function(resp) {
              resolve(resp.data.request_ids);
            });
        });
      };
    }
  ])
})();

