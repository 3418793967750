/*** IMPORTS FROM imports-loader ***/


(function() {
  angular.module('adhesiveApp').service('BillingService', [
    '$http', 'billingPrefixUrl',
    function($http, billingPrefixUrl) {
      var that = this,
        getBillingUrl = billingPrefixUrl + '/billing/',
        getMoneyUrl = billingPrefixUrl + '/money/',
        getSalesRepBilling = billingPrefixUrl + '/sales_rep_list',
        getMonthlyReportByAdvertiser = billingPrefixUrl + '/monthly_report_by_advertiser';

      this.fetchBilling = function(params) {
        return new Promise(function(resolve, reject){
            var options = {
              cache: false,
              params: params
            };

            $http.get(getBillingUrl, options).then(function(resp) {
              var billings = [];
              var rawToObject = function(row, index){
                var billing = {
                  advertiser_id: row.advertiser_id.value.replaceAll('-',''),
                  line_item_id: row.line_item_id.value.replaceAll('-',''),
                  calculated_fee: row.calculated_fee ? parseFloat(row.calculated_fee.value) : null,
                  imps: row.imps,
                  line_item_type: row.line_item_type,
                  revenue_type: row.revenue_type,
                  revenue_value: row.revenue_value ? parseFloat(row.revenue_value.value) : null,
                  total_convs: row.total_convs,
                  conversions: row.conversions,
                  total_cost: row.total_cost ? parseFloat(row.total_cost.value) : null
                };
                billings.push(billing);
              };
              angular.forEach(resp.data.billing, rawToObject);
              resolve(billings);
            });
        });
      };

      this.fetchMoney = function(params) {
        return new Promise(function(resolve, reject){
            var options = {
              cache: true,
              params: params
            };

            $http.get(getMoneyUrl, options).then(function(resp) {
              resolve(resp.data.money);
            });
        });
      };

      this.fetchSalesRepBilling = function(params) {
        return new Promise(function(resolve, reject){
          var options = {
            cache: true,
            params: params
          };

          $http.get(getSalesRepBilling, options).then(function(resp) {
            resolve(resp.data.salesrep);
          });
        });
      };

      this.fetchMonthlyReportByAdvertiser = function(advertiser_id, month) {
        let params = {aid: advertiser_id};
        if(month) {
          params["month"] = month;
        };

        return new Promise(function(resolve, reject){
          let options = {
            cache: false,
            params: params
          };

          $http.get(getMonthlyReportByAdvertiser, options).then(function(resp) {
            resolve(resp.data);
          });
        });
      };

    }
  ])
})();

