/*** IMPORTS FROM imports-loader ***/


(function() {
  'use strict';
  angular.module('adhesiveApp').service('Tables', ['userServ', 'time',
    function(userServ, time) {
      var that = this;

      this.create = function($scope, getList, updateParams) {
        $scope.items = [];
        $scope.dateRangeId = 'last_7_days';
        $scope.userId = userServ.getId();
        $scope.page = 1;
        $scope.pageVisible = 1;
        $scope.pagesCount = 0;
        $scope.user = userServ;
        $scope.loading = true;

        $scope.columns = [];
        $scope.orderKey = null;
        $scope.orderType = null;
        updateParams = updateParams || function () {};

        $scope.updateList = function(newpage) {
            if (newpage == $scope.page) {
                $scope.pageVisible = $scope.page;
                return;
            }
            $scope.loading = true;
            if (newpage) {
                $scope.pageVisible = $scope.page = newpage;
            }
            var params = {
                user_id: $scope.userId,
                from_date: time.getBackendDate($scope.startDate),
                to_date: time.getBackendDate($scope.endDate),
                page: $scope.page,
                order_key: $scope.orderKey,
                order_type: $scope.orderType
            };

            updateParams(params, $scope);

            getList(params).then(function(resp){
                $scope.items = resp.data.items;
                $scope.pagesCount = resp.data.pagination.num_pages;
                $scope.totals = resp.data.totals;
                $scope.loading = false;
                $scope.$apply();
            });
        };

        $scope.updateDateRange  = function(fromCalendar, startDate, endDate) {
            $scope.startDate = startDate;
            $scope.endDate = endDate;
            if(fromCalendar) {
                $scope.dateRangeId = '';
            }
            $scope.updateList();
        };

        $scope.nextPage = function() {
            $scope.updateList($scope.page + 1);
        };

        $scope.prevPage = function() {
            $scope.updateList($scope.page - 1);
        };

        $scope.setPage = function() {
            if ($scope.pageVisible > $scope.pagesCount) {
                $scope.pageVisible = $scope.pagesCount;
            }
            if ($scope.pageVisible < 1) {
                $scope.pageVisible = 1;
            }
            $scope.updateList($scope.pageVisible);
        }

        return {
          createColumn: function(key, label, getValue, sortable) {
            if (sortable === undefined) {
                sortable = true;
            }
            if (sortable) {
                var setOrder = function() {
                    if (this.ordered) {
                        if (this.ordering == 'asc') {
                            this.ordering = 'desc';
                        } else {
                            this.ordering = 'asc';
                        }
                    } else {
                        angular.forEach($scope.columns, function(column){
                            column.ordered = false;
                        });
                        this.ordered = true;
                    }
                    $scope.orderKey = this.key;
                    $scope.orderType = this.ordering;
                    $scope.updateList();
                };
            } else {
                var setOrder = function() {};
            }
            $scope.columns.push({
                key: key,
                label: label,
                ordering: 'asc',
                ordered: false,
                setOrder: setOrder,
                isSortedAsc: function() {return this.ordered && this.ordering == 'asc';},
                isSortedDesc: function() {return this.ordered && this.ordering == 'desc';},
                getValue: getValue || function(row) {return row[this.key];},
                sortable: sortable
            });
          }
        }
      }
    }
  ])
})();

